export const devMode = false
// SQUARE LOCATION_ID

const PROD_LOCATION = "LNT4F5CAWGX7W"
const TEST_LOCATION = "LTJGP881K0TQR"

// SQUARE APP_ID

const PROD_APP_ID = "sq0idp-MpVC4eogRKsE4JNPN67O2Q"
const SANDBOX_APP_ID = "sandbox-sq0idb-wO_sfLeJ6TfwAsK_kCtyfQ"

export const LOCATION_ID = devMode ? TEST_LOCATION : PROD_LOCATION
export const APP_ID = devMode ? SANDBOX_APP_ID : PROD_APP_ID
