import React from "react"
import { Provider } from "mobx-react"
import CartStore from "./src/stores/cart-store"
import { QueryClient, QueryClientProvider } from "react-query"
import { ReactQueryDevtools } from "react-query/devtools"
const queryClient = new QueryClient()

export default ({ element }) => (
  <>
    <QueryClientProvider client={queryClient}>
      <Provider cart={new CartStore()}>{element}</Provider>
      <ReactQueryDevtools initialIsOpen={true} />
    </QueryClientProvider>
  </>
)
